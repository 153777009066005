
// native
import { Injectable } from '@angular/core';

// addon
import { TrackJS } from 'trackjs';

// environment
import { environment } from 'src/environments/environment';

// build
import { BUILD_VERSION } from '../../../build/build-version';

// service
import { DocumentService } from '@readcube/smartcite-shared';
import { itemsAsDefaultCitationText, itemsAsRefs } from './citation.service';
import { genBiblioAndCitations } from './bibliography.service';
import { isFirefox } from './helpers.service';

// models
import { ReformatPayload, AppConfig, LegacyConvertPayload, Style, CitationMeta, BiblioMeta } from '../models/models';
import { ICitationOptions, DocumentCitations } from '@readcube/smartcite-shared/lib/models';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private documentService: DocumentService
  ) { }

  initDocumentSelectionPing = () => {
    let previousSelectionRefs = null;

    setInterval(() => {
      google.script.run
        .withFailureHandler()
        .withSuccessHandler((meta: CitationMeta | null) => {
          if (JSON.stringify(previousSelectionRefs) !== JSON.stringify(meta?.refs)) {
            this.documentService.onCitationSelect({
              ids: meta?.refs,
              options: meta?.options
            });
          }

          previousSelectionRefs = Array.isArray(meta?.refs) ? [...meta.refs] : meta?.refs;
        })
        .getCitationElementFromCursorOrSelection();
    }, 1000);
  };

  addCitation = async (items: any[], style: Style, locale: string, language: string, isAutoUpdate = false, citationOptions: ICitationOptions): Promise<void> => {
    TrackJS.console.info('Adding citation to doc');

    const hasItems =
      items && items.length;

    if (!hasItems)
      return;

    const citationText = itemsAsDefaultCitationText(items);
    const citationRefs = itemsAsRefs(items);
    const citationMeta: CitationMeta = {
      refs: citationRefs,
      options: citationOptions
    };

    if (isAutoUpdate) {
      return new Promise((resolve, reject) => {
        google.script.run
          .withFailureHandler(() => {
            reject();
          })
          .withSuccessHandler((response: void | string) => {
            if (typeof response === 'string')
              return reject(response);

            if (isFirefox())
              setTimeout(() => {
                this.reformatCitationsAndUpdateBibliography(resolve, reject, style, locale, language);
              }, 50);
            else
              this.reformatCitationsAndUpdateBibliography(resolve, reject, style, locale, language);
          })
          .addCitation(citationText, citationMeta, true);
      });
    }

    return new Promise((resolve, reject) => {
      google.script.run
        .withFailureHandler(() => {
          reject();
        })
        .withSuccessHandler((response: void | string) => {
          if (typeof response === 'string')
            return reject(response);

          resolve(response);
        })
        .addCitation(citationText, citationMeta);
    });

  };

  updateCitation = async (items: any[], style: Style, locale: string, language: string, isAutoUpdate = false, citationOptions: ICitationOptions): Promise<void | ReformatPayload> => {
    TrackJS.console.info('Update citation to doc');

    const citationText = itemsAsDefaultCitationText(items);
    const citationRefs = itemsAsRefs(items);
    const citationMeta: CitationMeta = {
      refs: citationRefs,
      options: citationOptions
    };

    if (isAutoUpdate) {
      return new Promise((resolve, reject) => {
        google.script.run
          .withFailureHandler(() => {
            reject();
          })
          .withSuccessHandler(() => {
            if (isFirefox())
              setTimeout(() => {
                this.reformatCitationsAndUpdateBibliography(resolve, reject, style, locale, language);
              }, 50);
            else
              this.reformatCitationsAndUpdateBibliography(resolve, reject, style, locale, language);
          })
          .updateCitation(citationText, citationMeta, true);
      });
    }

    return new Promise((resolve, reject) => {
      google.script.run
        .withFailureHandler(() => {
          reject();
        })
        .withSuccessHandler(() => {
          resolve();
        })
        .updateCitation(citationText, citationMeta);
    });
  };

  updateBibliography = (style: Style, locale: string, language: string): Promise<void | ReformatPayload> => {
    TrackJS.console.info('Updating bibliography');

    return new Promise((resolve, reject) => {
      this.reformatCitationsAndUpdateBibliography(resolve, reject, style, locale, language);
    });
  };

  reformatCitationsAndUpdateBibliography(resolve: Function, reject: Function, style: Style, locale: string, language: string) {
    google.script.run
      .withFailureHandler(() => {
        reject();
      })
      .withSuccessHandler(async (documentMetas: CitationMeta[]) => {
        if (!documentMetas)
          return resolve();

        if (!documentMetas.length) {
          google.script.run
            .withFailureHandler(() => {
              reject();
            })
            .withSuccessHandler(() => {
              resolve();
            })
            .replaceBibliography([], style, language);
          return;
        }

        const biblioResult = await genBiblioAndCitations(
          documentMetas, (r: any) => this.documentService.getBibliographyItems(r),
          (id: any) => this.documentService.getTypeSchema(id),
          (id: any) => this.documentService.getCustomFields(id),
          { style, locale });

        google.script.run
          .withFailureHandler(() => {
            reject();
          })
          .withSuccessHandler((r: void | ReformatPayload) => {
            resolve(r);
          })
          .reformatCitationsAndUpdateBibliography(biblioResult, style, language, documentMetas);
      })
      .getDocumentMetas();
  }

  getCitations = async (): Promise<DocumentCitations> => {
    TrackJS.console.info('Fetching citations');
    return new Promise((resolve, reject) => {
      google.script.run
        .withFailureHandler(() => {
          reject();
        })
        .withSuccessHandler((res: string[]) => {
          resolve({ ids: [...new Set(res)] });
        })
        .getDocumentCitationIds();
    });
  };

  convertLegacy = (style: Style, locale: string, language: string): Promise<LegacyConvertPayload> => {
    TrackJS.console.info('Converting legacy');
    return new Promise((resolve, reject) => {
      google.script.run
        .withFailureHandler(() => {
          reject();
        })
        .withSuccessHandler(async (documentMetas: CitationMeta[]) => {
          if (!documentMetas || !documentMetas.length) {
            const payload: LegacyConvertPayload = {
              citationsFound: false
            };
            resolve(payload);
            return;
          }

          const biblioResult = await genBiblioAndCitations(
            documentMetas, (r: any) => this.documentService.getBibliographyItems(r),
            (id: any) => this.documentService.getTypeSchema(id),
            (id: any) => this.documentService.getCustomFields(id),
            { style, locale });

          google.script.run
            .withFailureHandler(() => {
              reject();
            })
            .withSuccessHandler((response: void | ReformatPayload) => {
              const canceled = response && (<ReformatPayload>response).canceledByUser;
              const payload: LegacyConvertPayload = {
                citationsFound: true,
                allConverted: !canceled,
                canceled
              };
              resolve(payload);
            })
            .reformatCitationsAndUpdateBibliography(biblioResult, style, language, documentMetas, true);
        })
        .getDocumentMetas(true);
    });
  };

  convertToPlainText = (): Promise<void> => {
    TrackJS.console.info('Converting to plain text');
    return new Promise((resolve, reject) => {
      google.script.run
        .withFailureHandler(() => {
          reject();
        })
        .withSuccessHandler(() => {
          resolve();
        })
        .convertToPlainText();
    });
  };

  getBiblioMeta = (): Promise<BiblioMeta> => {
    TrackJS.console.info('Fetching biblio meta');
    return new Promise((resolve, reject) => {
      google.script.run
        .withFailureHandler(() => {
          reject();
        })
        .withSuccessHandler((meta: BiblioMeta) => {
          resolve(meta);
        })
        .getBiblioMeta();
    });
  };

  openLogin(): Promise<null> {
    return new Promise((resolve, reject) => {
      google.script.run
        .withFailureHandler(() => {
          reject();
        })
        .withSuccessHandler(() => {
          resolve(null);
        })
        .openLogin();
    });
  };

  openTutorial(): Promise<void> {
    return new Promise((resolve, reject) => {
      google.script.run
        .withFailureHandler(() => {
          reject();
        })
        .withSuccessHandler(() => {
          resolve();
        })
        .openTutorial();
    });
  }

  reloadAddon(): void {
    google.script.run.openAddon();
  }

  getVersion(): Promise<void> {
    return new Promise((resolve, reject) => {
      const versionScript = window.document.createElement('script');
      versionScript.src = `${environment.baseUrls.smartciteApp}/version.js`;

      versionScript.onload = () => {
        resolve();
        window.document.head.removeChild(versionScript);
      };

      window.document.head.appendChild(versionScript);
    });
  }

  // If Google Cloud deploy breaks for any reason, it can result in application prompting
  // for new version all the time, therefore buildTime isnt sent to calculate versioning
  // Also we dont have build time beacuse it changes main bundle hash for each build, which
  // could cause problems in case of rollback
  appConfig: AppConfig = {
    environment,
    addCitation: this.addCitation,
    updateCitation: this.updateCitation,
    updateBibliography: this.updateBibliography,
    clearCitationQueueOnAddUpdate: false,
    getCitations: this.getCitations,
    convertLegacy: this.convertLegacy,
    convertToPlainText: this.convertToPlainText,
    openLogin: this.openLogin,
    openTutorial: this.openTutorial,
    getBiblioMeta: this.getBiblioMeta,
    reloadAddon: this.reloadAddon,
    getVersion: this.getVersion,
    defaultAutoUpdate: false,
    buildVersion: BUILD_VERSION,
    buildTime: null
  };
}