// citeproc
import { asCiteprocItem } from '@readcube/readcube-citeproc';
import { apaStyle } from './citeproc/style';
import * as CSL from '@readcube/citeproc-es5';

// service
import { DocumentService } from '@readcube/smartcite-shared';
import { CitationMeta } from '../models/models';

// models
import { Style, BiblioResultFailure, BiblioResultSuccess } from '../models/models';

export type GetScQueryItems = (collItemIdPairs: { ids: string[]; }) =>
  ReturnType<typeof DocumentService.prototype.getBibliographyItems>;

export type GetTypeSchema = (id: string) =>
  ReturnType<typeof DocumentService.prototype.getTypeSchema>;

export type GetCustomFields = (id: string) =>
  ReturnType<typeof DocumentService.prototype.getCustomFields>

export const genBiblioAndCitations = async (
  citationsMetas: CitationMeta[],
  getItems: GetScQueryItems,
  getMapping: GetTypeSchema,
  getCustomFields: GetCustomFields,
  options?: { style?: Style, locale?: string, useSpan?: boolean; }
): Promise<BiblioResultFailure | BiblioResultSuccess> => {

  const citationsRefs =
    citationsMetas
      .map(meta => meta.refs);

  if (!citationsRefs || !citationsRefs.length)
    return { success: false, payload: { reason: 'error' } };

  const allItemRefs = (citationsRefs as any).flat();
  const uniqueItemRefs: any[] = [...new Set(allItemRefs)];
  const itemData = await getItems({ ids: uniqueItemRefs });

  if (!itemData)
    return { success: false, payload: { reason: 'error' } };

  if (!Array.isArray(itemData))
    return { success: false, payload: { reason: 'mark_missing_items', items: itemData.items } };

  const refCiteprocItemMap =
    itemData
      .reduce((all, item) => {

        if (!item)
          return all;

        const ref = item.collection_id + ':' + item.id;
        const mapping = getMapping(item.collection_id);

        const customFields = getCustomFields(item.collection_id);

        const citeprocItem = asCiteprocItem({ item, mapping, customFields })

        citeprocItem.id = ref;
        all[ref] = citeprocItem;

        return all;
      }, {});

  const { biblio, citations } = await bgBiblioProcess({
    refCiteprocItemMap,
    citationsMetas,
    style: options?.style.body || apaStyle,
    locale: options?.locale
  });

  let biblioHtml: string[] | undefined;

  if (biblio)
    biblioHtml = reformatBiblioHtml(biblio, { useSpan: options?.useSpan });

  citations.forEach(setCslErrorFlagAndText);

  return { success: true, payload: { biblioHtml, citations } };
};

const citeprocJsCslStyleError = '[CSL STYLE ERROR: reference with no printed form.]';

const setCslErrorFlagAndText = (citation: any) => {
  const hasCslError =
    citation
      ?.citationResult
      ?.includes(citeprocJsCslStyleError);

  if (hasCslError)
    citation.citationResult =
      citation
        .citationResult
        .replace(citeprocJsCslStyleError, 'METADATA ERROR DETECTED');

  citation = citation || {};
  citation.hasCslError = hasCslError;
};

const reformatBiblioHtml = (citeprocBiblioResult, options?: { useSpan?: boolean; }) => {

  const entryElem =
    options?.useSpan
      ? 'span'
      : 'p';

  const numericClass = 'csl-left-margin';
  const contentClass = 'csl-right-inline';

  const hasHangingIdent = citeprocBiblioResult[0].hangingindent;
  const paragraphStyle =
    hasHangingIdent
      ? `style="margin-left: 20px; text-indent: -20px; display: inline-block;"`
      : '';

  const biblioHtmlString = citeprocBiblioResult[1].join('');
  const isNumericStyle =
    biblioHtmlString.includes(numericClass)
    && biblioHtmlString.includes(contentClass);

  const biblioHtml =
    new DOMParser()
      .parseFromString(biblioHtmlString, 'text/html');

  const cslEntries = Array.from(biblioHtml.querySelectorAll('.csl-entry'));

  const biblioHtmlStrings =
    cslEntries
      .map(cslEntryElem => {

        let entryHtml: string;

        if (isNumericStyle) {

          const contentEl = cslEntryElem.querySelector(`.${contentClass}`);
          const numEl = cslEntryElem.querySelector(`.${numericClass}`);

          if (!contentEl || !numEl)
            return '';

          const content = contentEl.innerHTML;
          const num = numEl.innerHTML;
          const html = num + ' ' + content;

          entryHtml = `<${entryElem} ${paragraphStyle} class="csl-entry">${html}</${entryElem}>`;
        } else {

          cslEntryElem
            .querySelectorAll(`.csl-indent`)
            .forEach(e => {
              if (e instanceof HTMLElement) {
                e.style.marginLeft = '1cm';
                e.style.marginTop = '0.4cm';
              }
            });

          entryHtml = `<${entryElem} ${paragraphStyle} class="csl-entry">${cslEntryElem.innerHTML}</${entryElem}>`;
        }

        return entryHtml;
      });

  return biblioHtmlStrings;
};

const bgBiblioProcess = (data: { refCiteprocItemMap: {}, citationsMetas: CitationMeta[], style: string, locale: string; }): { biblio: any, citations: any; } => {
  const locatorLabels = {
    Book: 'book',
    Chapter: 'chapter',
    Column: 'column',
    Figure: 'figure',
    Folio: 'folio',
    Issue: 'issue',
    Line: 'line',
    Note: 'note',
    Opus: 'opus',
    Page: 'page',
    Paragraph: 'paragraph',
    Part: 'part',
    Section: 'section',
    'Sub Verbo': 'sub verbo',
    Verse: 'verse',
    Volume: 'volume'
  };

  const citeprocSys = {
    retrieveLocale: () => data.locale,
    retrieveItem: ref => data.refCiteprocItemMap[ref],
  };

  const citeprocEngine = new CSL.Engine(citeprocSys, data.style);
  citeprocEngine.updateItems(Object.keys(data.refCiteprocItemMap));
  citeprocEngine.setOutputFormat('html');
  const biblio = citeprocEngine.makeBibliography();
  const citationsPre = {};
  const citationIdCitationResult = {};

  const citations =
    data.citationsMetas
      .map(citationMeta => {

        const validRefs =
          citationMeta
            .refs
            .filter(r => data.refCiteprocItemMap[r]);

        const citationItems =
          validRefs
            .map(r => {
              const item: any = { id: r };

              const itemOptions = citationMeta?.options?.items?.[r];
              if (!itemOptions)
                return item;

              const positionOption = itemOptions?.position;

              if (positionOption) {
                item.label = locatorLabels[positionOption.name];
                item.locator = positionOption.value;
              }

              if (itemOptions.prefix)
                item.prefix = itemOptions.prefix;

              if (itemOptions.suffix)
                item.suffix = itemOptions.suffix;

              if (itemOptions.suppressAuthor)
                item['suppress-author'] = itemOptions.suppressAuthor;

              return item;
            });

        if (!citationItems || !citationItems.length)
          return { refs: [], citationResult: null };

        const citeprocCitation = {
          properties: { noteIndex: 0 },
          citationItems,
        };

        const result =
          citeprocEngine
            .processCitationCluster(citeprocCitation, Object.entries(citationsPre), []);

        const citationResultArray =
          result
          && result.length
          && result[1];

        if (citationResultArray)
          for (const citationResult of citationResultArray) {

            const citationText = citationResult[1];
            const cId = citationResult[2];

            citationIdCitationResult[cId] = citationText;
          }

        const citationId =
          citationResultArray
          && citationResultArray.length
          && citationResultArray[citationResultArray.length - 1]
          && citationResultArray[citationResultArray.length - 1][2];

        if (!citationsPre[citationId])
          citationsPre[citationId] = 0;

        const metaForValidRefs: any = { refs: validRefs };

        if (citationMeta.options) {

          metaForValidRefs.options = {};
          metaForValidRefs.options.manual_text_override = citationMeta.options.manual_text_override;

          if (citationMeta.options.items) {

            metaForValidRefs.options.items = {};

            for (const entry of Object.entries(citationMeta.options.items)) {

              const [citationMetaKey, citationMetaValue] = entry;

              if (!validRefs.includes(citationMetaKey))
                continue;

              metaForValidRefs.options.items[citationMetaKey] = citationMetaValue;
            }
          }
        }

        return { citationId, meta: metaForValidRefs };
      });

  citations
    .forEach(citation => {
      const citationResult = citationIdCitationResult[citation.citationId];
      citation.citationResult = citationResult;

      const manualTextOverride =
        citation.meta
        && citation.meta.options
        && citation.meta.options.manual_text_override;

      if (manualTextOverride)
        citation.citationResult = manualTextOverride;
    });

  return {
    biblio,
    citations
  };
};